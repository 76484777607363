// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Retrieves a color value from the $material-colors Sass map\n * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/\n *\n * @function\n * @param {string} color-name             - quoted, lower-case, dasherized color\n *                                          name (e.g. 'pink', 'amber')\n * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.\n *                                          '200', 'a100')\n */\n.UYPGMKfVsrJoMe0qXX2HPA\\=\\= div {\n  background: #64b5f6;\n}\n\n.DslwDP5rRhPmy9G78ZgK\\+w\\=\\= div {\n  background: #81c784;\n}\n\n.ODpFfE\\+RXpr94Z7iK93vBA\\=\\= div {\n  background: #ffb74d;\n}\n\n.jieUh0nMfa7RgapcdZmN8g\\=\\= div {\n  background: #e57373;\n}\n\n.q1KqTR9V8e8AZrx88VQn2Q\\=\\= div {\n  background: #616161;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgInfo": "UYPGMKfVsrJoMe0qXX2HPA==",
	"bgSuccess": "DslwDP5rRhPmy9G78ZgK+w==",
	"bgWarning": "ODpFfE+RXpr94Z7iK93vBA==",
	"bgError": "jieUh0nMfa7RgapcdZmN8g==",
	"bgDefault": "q1KqTR9V8e8AZrx88VQn2Q=="
};
module.exports = ___CSS_LOADER_EXPORT___;
